<template>
  <div class="mt-8">
    <vee-form
      class="sm:flex sm:space-x-6"
      @submit="submitPayeAssessment"
      :validation-scheme="validationScheme"
      v-slot="{ errors }"
    >
      <div class="sm:flex sm:w-1/3 sm:space-x-10">
        <div class="relative w-full mb-2 sm:mb-0">
          <div class="flex items-center justify-between">
            <label for="tin" class="leading-7 text-sm text-gray-600"
              >TIN (JTB if available)</label
            >
          </div>
          <vee-field
            name="tin"
            :rules="validationScheme.tin"
            type="number"
            id="tin"
            autocomplete="on"
            v-model="pitDetails.tin"
            class="w-full bg-white rounded border border-gray-300 focus:border-blue-primary focus:ring-2 focus:ring-blue-primary text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
          />
          <span class="text-error">{{ errors.tin }}</span>
        </div>
      </div>
      <div class="relative sm:w-34  mb-2 sm:mb-0">
        <div class="flex items-center justify-between">
          <label for="num_of_months" class="leading-7 text-sm text-gray-dark"
            >Months worked</label
          >
        </div>
        <vee-field
          type="number"
          id="num_of_months"
          name="num_of_months"
          :rules="validationScheme.required"
          v-model="pitDetails.num_of_months"
          autocomplete="off"
          class="w-full bg-white rounded border border-gray-300 focus:border-blue-primary focus:ring-2 focus:ring-blue-primary text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
        />
        <span class="text-error">{{ errors.num_of_months }}</span>
      </div>
      <div class="relative sm:w-34  mb-2 sm:mb-0">
        <div class="flex items-center justify-between">
          <label for="assessment_year" class="leading-7 text-sm text-gray-dark"
            >Assessment Year</label
          >
        </div>
        <vee-field
          type="number"
          id="assessment_year"
          name="assessment_year"
          :rules="validationScheme.required"
          v-model="pitDetails.assessment_year"
          autocomplete="off"
          class="w-full bg-white rounded border border-gray-300 focus:border-blue-primary focus:ring-2 focus:ring-blue-primary text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
        />
        <span class="text-error">{{ errors.assessment_year }}</span>
      </div>
      <button
        id="btnPayeAssessment"
        :disabled="assessing"
        class=" w-full h-12 sm:w-1/3 mt-6 px-8 py-2 font-semibold text-white text-lg transition duration-500 ease-in-out transform rounded-lg shadow-xl bg-gradient-to-r from-green-700 hover:from-green-600 to-green-600 hover:to-green-700 focus:ring focus:ring-green-700 focus:outline-none"
      >
        <div v-if="!assessing">Assess taxpayer</div>
        <div v-else class="flex items-center space-x-4 justify-center">
          <i class="fas fa-circle-notch animate-spin"></i>
          <span>Please wait...</span>
        </div>
      </button>
    </vee-form>
    <view-slider
      @savePayeAssessment="savePayeAssessment"
      title="Income Declaration"
    />
  </div>
</template>

<script>
import { ref } from "vue";
import useAssessment from "@/mixins/assessment";
import { useStore } from "vuex";
import ViewSlider from "./ViewSlider";
import { emitter } from "@/main.js";

export default {
  name: "PayeAssessment",
  components: { ViewSlider },
  props: {
    validationScheme: {
      type: Object,
      required: true,
    },
  },
  emits: ["payeAssessment", "result"],
  setup(props, { emit }) {
    const assessing = ref(false);
    const { pitDetails } = useAssessment();
    const store = useStore();

    const submitPayeAssessment = async () => {
      if (store.getters["assessment/getPayeIncomes"].length > 0) {
        assessing.value = true;
        delete pitDetails.value.income;
        delete pitDetails.value.bim_levy;
        delete pitDetails.value.bus_prem;
        pitDetails.value.incomes = await store.getters["assessment/getPayeIncomes"];
        pitDetails.value.deductions =
          await store.getters["assessment/getPayeDeductions"];

        await emit("payeAssessment", pitDetails.value);
        assessing.value = false;
      } else {
        emit("result", {
          state: "error",
          msg: "But you've not entered any source of income...",
        });
      }
    };

    const savePayeAssessment = async () => {
      try {
        pitDetails.value.saveRecord = true;
        let result = await store.dispatch("assessment/assessTaxPayer", {
          mode: "save",
          type: "PAYE",
          data: pitDetails.value,
        });
        if (result.status === "error")
          emit("result", { status: "error", msg: result });
        else {
          emit("result", { status: "success", msg: result });
          emitter.emit("resetAssessmentResult");
        }
      } catch (e) {
        console.log(e);
        emit("result", { status: "error", msg: e });
      }
    };

    return {
      assessing,
      pitDetails,
      submitPayeAssessment,
      savePayeAssessment,
    };
  },
};
</script>

<style scoped></style>
