<template>
  <div v-click-away="away" class="dropdown-wrapper relative" :class="classList">
    <button
      type="button"
      @click.prevent="showMenu = !showMenu"
      class="w-full flex justify-between items-center text-blue-primary border-2 border-blue-primary font-bold py-2 px-4 whitespace-no-wrap rounded transition duration-300 overflow-hidden "
    >
      <span class=" text-sm overflow-hidden">{{ dropdownTitle }}</span>
      <i
        :class="`fas ${showMenu ? 'fa-chevron-up' : 'fa-chevron-down'} ml-2`"
      ></i>
    </button>
    <transition :name="'fade'">
      <div
        :class="'bg-' + color + '-500 ' + classList"
        class="dropdown-menu max-h-52 overflow-y-auto text-white mt-1 rounded absolute z-10 shadow-lg"
        v-if="showMenu"
      >
        <ul class="list-none overflow-hidden rounded">
          <li v-for="item in items" :key="item.id">
            <p
              @click.prevent="selectItem(item)"
              class="flex py-2 px-4 transition duration-300 hover:bg-blue-primary-dark cursor-pointer"
              :class="'theme-' + color"
            >
              {{ item.organization_type + " - ₦" + formatAmount(item.amount) }}
            </p>
          </li>
        </ul>
      </div>
    </transition>
    <span class="w-full text-right text-gray-400 ml-4 font-medium text-sm">
      ₦{{ selectedAmount }}</span
    >
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import numeral from "numeral";
export default {
  name: "BIMDropDown",
  props: {
    title: { type: String, required: false },
    items: {
      type: Array,
    },
    classList: { type: Array },
    color: { type: String, default: "blue" },
  },
  emits: ["bim-selected"],
  setup(props, { emit }) {
    const showMenu = ref(false);
    const dropdownTitle = ref(props.items[0]?.organization_type || "");
    const amount = ref(props.items[0]?.amount || 0.0);

    const selectedAmount = computed(() => {
      return formatAmount(amount.value);
    });

    const selectItem = (item) => {
      dropdownTitle.value = item.organization_type;
      amount.value = item.amount;
      showMenu.value = false;
      emit("bim-selected", item);
    };
    const away = () => {
      showMenu.value = false;
    };
    const formatAmount = (amt) => {
      return numeral(amt).format("0,0.00");
    };

    onMounted(() => {
      // console.log(props.title);
    });

    return {
      away,
      showMenu,
      selectItem,
      selectedAmount,
      dropdownTitle,
      formatAmount,
    };
  },
};
</script>

<style></style>
