<template>
  <div class="mt-8 flex-col lg:flex lg:flex-row lg:items-start">
    <div
      class="gallery border-2 rounded my-5 h-auto  bg-white"
      :style="
        `${
          screenWidth > 800
            ? screenWidth > 1024
              ? `width: 690px`
              : 'width: 650px'
            : 'width:' + (screenWidth - (screenWidth < 550 ? 40 : 60)) + 'px'
        }`
      "
    >
      <div class="top flex p-2 border-b select-none">
        <div
          class="heading text-gray-800 w-full pl-3 font-semibold my-auto"
        ></div>
        <div class="buttons h-10 ml-auto flex text-gray-600 mr-1">
          <svg
            action="prev"
            class="w-7 border-2 rounded-l-lg p-1 cursor-pointer border-r-0"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              action="prev"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M10 19l-7-7m0 0l7-7m-7 7h18"
            />
          </svg>
          <svg
            action="next"
            class="w-7 border-2 rounded-r-lg p-1 cursor-pointer"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              action="next"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M14 5l7 7m0 0l-7 7m7-7H3"
            />
          </svg>
        </div>
      </div>
      <div class="content-area w-full overflow-hidden">
        <div class="platform shadow-xl h-full flex">
          <!-- frame start -->
          <div
            class="each-frame border-box flex-none h-full w-full"
            title="Income Declaration"
          >
            <!-- title shows in top -->
            <!-- this is full editable area -->
            <div class="main  w-full px-5 py-8 ">
              <div class=" w-full sm:flex sm:items-end sm:space-x-4">
                <div class="relative mb-2 sm:w-60 sm:mb-0">
                  <div class="flex items-center justify-between">
                    <label
                      for="income_type"
                      class="leading-7 text-sm text-gray-dark"
                      >Income Source</label
                    >
                  </div>
                  <drop-down
                    name="income_type"
                    :items="incomeTypes"
                    :title="''"
                    :menuClassList="[' h-32 overflow-auto ']"
                    :color="'gray'"
                    :classList="[
                      ' w-full sm:w-60 rounded border border-gray-500 ',
                    ]"
                    @item-selected="selectIncomeType"
                  />
                </div>
                <div class="relative sm:w-40 sm:mb-0">
                  <div class="">
                    <label for="amount" class="leading-7 text-sm text-gray-dark"
                      >Amount</label
                    >
                    <input
                      type="number"
                      id="amount"
                      name="amount"
                      v-model="income.amount"
                      @keyup.enter="addIncome"
                      autocomplete="off"
                      class="w-full bg-white rounded border border-gray-300 focus:border-gray-600 focus:ring-2 focus:ring-gray-600 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                  </div>
                </div>
                <button
                  @click.prevent="addIncome"
                  class="w-full sm:w-auto mt-4 sm:mt-0 block transition duration-300 ease-in-out focus:outline-none bg-gray-600 text-white text-md text-center py-2 px-4 cursor-pointer rounded hover:bg-gray-700 "
                >
                  <i class="fas fa-plus"></i>
                </button>
              </div>
              <div class="relative mt-4  h-40 sm:h-80  overflow-auto">
                <table
                  class="w-full table-auto text-left whitespace-no-wrap mb-8 sm:mb-0"
                >
                  <thead>
                    <tr>
                      <th
                        class="w-64 px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100 rounded-tl rounded-bl"
                      >
                        Type
                      </th>
                      <th
                        class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100 text-right"
                      >
                        Amount
                      </th>
                      <th
                        class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm text-right bg-gray-100"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(inc, index) in incomes" :key="inc">
                      <td class="px-4 py-3">{{ inc.type }}</td>
                      <td class="px-4 py-3 text-right">
                        ₦{{ formatAmount(inc.amount) }}
                      </td>
                      <td
                        class="px-4 py-3 flex justify-around sm:justify-end sm:pr-6 "
                      >
                        <button
                          @click.prevent="removeIncome(index)"
                          class="block transition h-6 w-6 duration-300 ease-in-out focus:outline-none bg-red-400 text-white text-md text-center  cursor-pointer rounded-full hover:bg-red-500 "
                        >
                          <i class="fas fa-minus"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- frame end -->

          <!-- Deductions frame start -->
          <div
            class="each-frame border-box flex-none h-full w-full"
            title="Deductions"
          >
            <!-- title shows in top -->
            <!-- this is full editable area -->
            <div class="main  w-full px-5 py-8 ">
              <div class="sub w-full sm:flex sm:items-end sm:space-x-4">
                <div class="relative mb-2 sm:w-60 sm:mb-0">
                  <div class="flex items-center justify-between">
                    <label
                      for="deduction_type"
                      class="leading-7 text-sm text-gray-dark"
                      >Deduction Type</label
                    >
                  </div>
                  <drop-down
                    name="deduction_type"
                    :items="deductionTypes"
                    :title="''"
                    :menuClassList="[' h-32 overflow-auto ']"
                    :color="'gray'"
                    :classList="[
                      ' w-full sm:w-60 rounded border border-gray-500 ',
                    ]"
                    @item-selected="selectDeductionType"
                  />
                </div>
                <div class="relative sm:w-40 sm:mb-0">
                  <div class="">
                    <label for="amount" class="leading-7 text-sm text-gray-dark"
                      >Amount</label
                    >
                    <input
                      type="number"
                      id="ded_amount"
                      name="ded_amount"
                      v-model="deduction.amount"
                      @keyup.enter="addDeduction"
                      autocomplete="off"
                      class="w-full bg-white rounded border border-gray-300 focus:border-gray-600 focus:ring-2 focus:ring-gray-600 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                  </div>
                </div>
                <button
                  @click.prevent="addDeduction"
                  class="w-full sm:w-auto mt-4 sm:mt-0 block transition duration-300 ease-in-out focus:outline-none bg-gray-600 text-white text-md text-center py-2 px-4 cursor-pointer rounded hover:bg-gray-700 "
                >
                  <i class="fas fa-plus"></i>
                </button>
              </div>
              <div class="mt-4 w-full h-40 sm:h-80  overflow-auto">
                <table
                  class="table-auto w-full text-left whitespace-no-wrap mb-8 sm:mb-0"
                >
                  <thead>
                    <tr>
                      <th
                        class="w-80 px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100 rounded-tl rounded-bl"
                      >
                        Type
                      </th>
                      <th
                        class="w-40 px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100 text-right"
                      >
                        Amount
                      </th>
                      <th
                        class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm text-right bg-gray-100"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(ded, index) in deductions" :key="ded">
                      <td class="px-4 py-3">{{ ded.type }}</td>
                      <td class="px-4 py-3 text-right ">
                        ₦{{ formatAmount(ded.amount) }}
                      </td>
                      <td
                        class="px-4 py-3 flex justify-around sm:justify-end sm:pr-6 "
                      >
                        <button
                          @click.prevent="removeDeduction(index)"
                          class="block transition h-6 w-6 duration-300 ease-in-out focus:outline-none bg-red-400 text-white text-md text-center  cursor-pointer rounded-full hover:bg-red-500 "
                        >
                          <i class="fas fa-minus"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- frame end -->
        </div>
      </div>
    </div>

    <div
      class="w-full mt-4 pt-4 pb-4 px-8 lg:ml-4 lg:w-80 xl:w-full h-full bg-white shadow-md rounded-lg overflow-hidden"
    >
      <div class="flex flex-col mb-4">
        <h2 class="text-gray-700 font-semibold text-2xl tracking-wide mb-4">
          Assessment Result
        </h2>

        <div class="border-b-2 border-gray-50 py-2">
          <p class="text-sm font-medium text-gray-500">
            Gross income:
          </p>
          <p
            class="mt-1 text-md text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
          >
            N {{ formatAmount(assessmentResult?.gross_income || 0) }}
          </p>
        </div>

        <div class="border-b-2 border-gray-50 py-2 mb-8">
          <p class="text-sm font-medium text-gray-500">
            Tax Deductions:
          </p>
          <p
            class="mt-1 text-md text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
          >
            N {{ formatAmount(assessmentResult?.total_deduction || 0) }}
          </p>
        </div>
      </div>

      <div
        class="lg:py-2 xl:py-3 my-3 mt-6 sm:flex sm:items-center sm:justify-between px-2 sm:space-x-2 border-b-2 bg-gray-100 rounded border-gray-50  sm:text-center"
      >
        <p class="text-sm font-semibold text-gray-500">
          Tax Relief:
        </p>
        <p class="mt-1 text-md text-gray-700 sm:mt-0 sm:col-span-2 font-bold">
          N {{ formatAmount(assessmentResult?.tax_relief || 0) }}
        </p>
      </div>
      <div
        class="sm:flex sm:items-center sm:justify-between px-2 sm:space-x-2 border-b-2 bg-gray-100 rounded border-gray-50 lg:py-2 xl:py-3 my-3 sm:text-center"
      >
        <p class="text-sm font-semibold text-gray-500">
          Taxable:
        </p>
        <p class="mt-1 text-md text-gray-700 sm:mt-0 sm:col-span-2 font-bold">
          N {{ formatAmount(assessmentResult?.taxable_income || 0) }}
        </p>
      </div>
      <div
        class="sm:flex sm:items-center sm:justify-between px-2 sm:space-x-2 border-b-2 bg-gray-100 rounded border-gray-50 lg:py-2 xl:py-3 my-3 sm:text-center"
      >
        <p class="text-sm font-semibold text-gray-500">
          Tax Due:
        </p>
        <p class="mt-1 text-md text-gray-700 sm:mt-0 sm:col-span-2 font-bold">
          N {{ formatAmount(assessmentResult?.tax_payable || 0) }}
        </p>
      </div>

      <div class="py-2 my-4">
        <button
          @click="savePayeAssessment"
          :disabled="savingAssessment"
          class="w-full block tracking-widest uppercase text-center shadow bg-blue-600 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-3 px-10 rounded"
        >
          <div v-if="!savingAssessment">Save assessment</div>
          <div v-else class="flex items-center space-x-4 justify-center">
            <i class="fas fa-circle-notch animate-spin"></i>
            <span>Please wait...</span>
          </div>
        </button>
      </div>

      <!--      <div class="py-2">-->
      <!--        <a-->
      <!--          href="#"-->
      <!--          class="block tracking-widest uppercase text-center shadow bg-pink-600 hover:bg-pink-700 focus:shadow-outline focus:outline-none text-white text-xs py-3 px-10 rounded"-->
      <!--          >Print bill</a-->
      <!--        >-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import { onMounted, ref, onBeforeUnmount, computed } from "vue";
import useAssessment from "@/mixins/assessment";
import DropDown from "../shared/DropDown";
import { emitter } from "@/main.js";
import { useStore } from "vuex";

export default {
  components: { DropDown },
  props: ["title", "content"],
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    let G = {};
    const drawerExpanded = ref(false);
    const assessmentResult = ref({});
    const savingAssessment = ref(false);
    const store = useStore();
    const incomeTypes = ref([
      "Salary",
      "Allowance",
      "Commission",
      "Bonus",
      "Gratuity",
      "Dividend",
      "Interest",
      "Rent",
      "Others",
    ]);
    const deductionTypes = ref([
      "Pension",
      "NHF Contribution",
      "NHIS Contribution",
      "Life Assurance Premium",
      "Gratuity",
    ]);
    const screenWidth = ref(window.screen.availWidth);
    const income = ref({
      type: incomeTypes.value[0],
      amount: 0.0,
    });
    const deduction = ref({
      type: deductionTypes.value[0],
      amount: 0.0,
    });
    const {
      addToIncomes,
      addToDeductions,
      removeIncome,
      removeDeduction,
      formatAmount,
    } = useAssessment();
    const incomes = computed(() => store.getters["assessment/getPayeIncomes"]);
    const deductions = computed(
      () => store.getters["assessment/getPayeDeductions"]
    );

    function resetIncomeValues() {
      income.value = {
        type: income.value.type,
        amount: 0.0,
      };
    }
    function resetDeductionValues() {
      deduction.value = {
        type: deduction.value.type,
        amount: 0.0,
      };
    }

    function gallery() {
      this.index = 0;
      this.load = async function() {
        this.rootEl = document.querySelector(".gallery");
        this.platform = this.rootEl.querySelector(".platform");
        this.frames = this.platform.querySelectorAll(".each-frame");
        this.contentArea = this.rootEl.querySelector(".content-area");
        this.width = parseInt(this.rootEl.style.width);
        this.limit = { start: 0, end: this.frames.length - 1 };
        await this.frames.forEach((each) => {
          each.style.width = this.width + "px";
        });
        this.goto(this.index);
      };

      this.set_title = function() {
        this.rootEl.querySelector(".heading").innerText = this.frames[
          this.index
        ].getAttribute("title");
      };
      this.next = function() {
        this.platform.style.right = this.width * ++this.index + "px";
        this.set_title();
      };
      this.prev = function() {
        this.platform.style.right = this.width * --this.index + "px";
        this.set_title();
      };
      this.goto = function(index) {
        this.platform.style.right = this.width * index + "px";
        this.index = index;
        this.set_title();
      };
      this.load();
    }
    function initSlider() {
      G = new gallery();
      G.rootEl.addEventListener("click", function(t) {
        var val = t.target.getAttribute("action");
        if (val == "next" && G.index != G.limit.end) {
          G.next();
        }
        if (val == "prev" && G.index != G.limit.start) {
          G.prev();
        }
        if (val == "goto") {
          let rv = t.target.getAttribute("goto");
          rv = rv == "end" ? G.limit.end : rv;
          G.goto(parseInt(rv));
        }
      });
      document.addEventListener("keyup", function(t) {
        var val = t.keyCode;
        if (val == 39 && G.index != G.limit.end) {
          G.next();
        }
        if (val == 37 && G.index != G.limit.start) {
          G.prev();
        }
      });
    }
    function addIncome() {
      if (income.value.amount > 0) {
        addToIncomes(income.value);
      }
      resetIncomeValues();
    }

    function selectIncomeType(type) {
      income.value.type = type;
    }

    function addDeduction() {
      if (deduction.value.amount > 0) {
        addToDeductions(deduction.value);
      }
      resetDeductionValues();
    }

    function selectDeductionType(type) {
      deduction.value.type = type;
    }

    const savePayeAssessment = async () => {
      try {
        savingAssessment.value = true;
        emit("savePayeAssessment");
      } catch (e) {
        console.log(e);
      } finally {
        savingAssessment.value = false;
      }
    };

    onMounted(() => {
      try {
        initSlider();

        emitter.on("paye_assessment", (data) => {
          
          assessmentResult.value = data;
        });
        emitter.on("resetAssessmentResult", () => {
          assessmentResult.value = {};
        });

        window.addEventListener("resize", () => {
          screenWidth.value = window.screen.availWidth;
          // initSlider();
          G.load();
        });
      } catch (error) {
        console.log(error);
      }
    });
    onBeforeUnmount(() => {
      window.removeEventListener("resize", () => {});
    });

    return {
      savePayeAssessment,
      savingAssessment,
      incomeTypes,
      deductionTypes,
      screenWidth,
      addIncome,
      removeIncome,
      selectIncomeType,
      income,
      incomes,
      formatAmount,
      addDeduction,
      removeDeduction,
      selectDeductionType,
      assessmentResult,
      deduction,
      deductions,
      drawerExpanded,
    };
  },
};
</script>

<style scoped>
.platform {
  position: relative;
  transition: right 0.3s;
}
</style>
