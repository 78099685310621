<template>
  <div class="pt-8 text-gray-dark sec-main-h overflow-y-auto px-4 hide-scrollbar md:show-scrollbar ">
    <!-- component -->

    <property-tax-demand-notice-preview v-if="openPropertyTaxDemandNoticePreviewModal"
      @cancelPrint="openPropertyTaxDemandNoticePreviewModal = false" :property="propertyToPreview"
      :assessment_year="filter.assessment_year">
    </property-tax-demand-notice-preview>

    <div class=" bg-gradient-to-r from-blue-primary to-blue-600 sm:h-50 p-8 rounded-t">
      <div class="sm:container mx-auto ">
        <nav class="flex items-center mb-4">
          <!-- <a
            class="no-underline text-white px-4 font-medium mr-3 bg-indigo hover:bg-indigo-darker"
            href="#"
            >Cardamom</a
          > -->

          <div class="flex items-center gap-2 sm:gap-8 text-white">
            <!-- <label class="inline-flex items-center">
              <input type="radio" name="vehicle" class="h-5 w-5 text-red-600" />
              <span class="ml-2 ">
                Date created
              </span>
            </label>
            <label class="inline-flex items-center">
              <input type="radio" name="vehicle" class="h-5 w-5 text-red-600" />
              <span class="ml-2 ">
                Assessment Year
              </span>
            </label>
            <label class="inline-flex items-center">
              <input type="radio" name="vehicle" class="h-5 w-5 text-red-600" />
              <span class="ml-2 ">
                Both
              </span>
            </label> -->
            <div @click="dateRangeFilter = !dateRangeFilter" class="flex items-center space-x-2 cursor-pointer">
              <input type="checkbox" name="date_range_filter" v-model="dateRangeFilter"
                class="focus:ring cursor-pointer rounded-lg" />
              <span class="text-white font-medium rounded text-sm">Date created</span>
            </div>
            <div @click="assessmentYearFilter = !assessmentYearFilter"
              class="flex items-center space-x-2 cursor-pointer">
              <input type="checkbox" name="assessment_year_filter" v-model="assessmentYearFilter"
                class="focus:ring cursor-pointer rounded-lg" />
              <span class="text-white font-medium rounded text-sm">Assessment Year</span>
            </div>
          </div>
        </nav>
        <div class="flex flex-col sm:flex-row sm:items-center rounded sm:h-12 sm:bg-white w-full">
          <drop-down :items="assessmentTypes" :title="''" :showIcon="true" @item-selected="selectType" :classList="[
            ' w-full sm:w-32 border-b-2 border-blue-600 sm:border-white bg-white rounded mb-2 sm:mb-0 sm:rounded-none sm:border-none',
          ]"></drop-down>
          <input @keyup.enter="search" autocomplete="on"
            class="w-full h-10 sm:h-12 mt-1 sm:mt-0 rounded focus:outline-none focus:shadow-outline md:text-xl px-4 shadow-lg sm:border-l-2 sm:border-blue-primary-dark "
            type="search" v-model="filter.tin" placeholder="Enter tax payer TIN..." />
          <button @click="search"
            class="text-white flex items-center rounded sm:rounded-none sm:flex-none w-full mt-4 sm:mt-0 sm:w-10 bg-green-500 h-10 sm:h-12 px-2 focus:outline-none hover:ring-2 hover:ring-primary-dark">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 hidden sm:block" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M8 16l2.879-2.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242zM21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span class="block sm:hidden w-full text-center text-lg font-medium">
              <div v-if="!fetchingAssessments">Search</div>
              <div v-else class="flex items-center space-x-4 justify-center">
                <i class="fas fa-circle-notch animate-spin"></i>
                <span>Please wait...</span>
              </div>
            </span>
          </button>
        </div>

        <div
          class="flex flex-col  sm:flex-row sm:items-end sm:space-x-2 text-white mt-4 sm:mt-6 sm:order-first sm:mr-10">
          <template v-if="dateRangeFilter">
            <date-picker :classList="['text-white text-sm sm:text-md']" :datePickerClass="['text-primary']"
              :newDate="selectedDates.startDate" :title="'Start Date'" @updateDate="updateStartDate"></date-picker>
            <date-picker :classList="['text-white text-sm sm:text-md']"
              :datePickerClass="['text-primary ml-1.5 sm:ml-0']" :newDate="selectedDates.endDate" :title="'End Date'"
              @updateDate="updateEndDate"></date-picker>
          </template>

          <div v-if="assessmentYearFilter"
            class="relative flex flex-row items-center justify-between mt-2 w-full sm:w-auto">
            <label for="assessment_year"
              class="leading-relaxed md:font-semibold text-md sm:text-lg mr-6 sm:mr-2 text-white">Year :</label>
            <input type="number" id="assessment_year" name="assessment_year" v-model="filter.assessment_year"
              autocomplete="off"
              class="w-20 bg-white rounded border border-gray-300 focus:border-blue-primary focus:ring-2 focus:ring-blue-primary text-base outline-none text-primary font-medium py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
          </div>
        </div>
      </div>
    </div>

    <div class="-my-2 py-2 h-5/6 sm:h-3/4 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8  lg:px-8">
      <div v-if="fetchingAssessments" class="w-full mt-2 flex items-center text-center">
        <i :class="
          `lg:focus:shadow-sm w-12 mx-auto text-center fas fa-circle-notch mt-4 text-5xl text-blue-primary animate-spin`
        "></i>
      </div>
      <div v-else
        class="align-middle inline-block min-w-full shadow overflow-hidden bg-white shadow-dashboard  pt-3 rounded-bl-lg rounded-br-lg">
        <table class="min-w-full table-striped">
          <thead>
            <tr class="text-xs ">
              <th class="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-gray-700 tracking-wider">
                TIN
              </th>
              <th class="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-700 tracking-wider">
                Tax payer
              </th>
              <th class="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-700 tracking-wider">
                Type
              </th>
              <th class="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-700 tracking-wider">
                Gross
              </th>
              <th class="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-700 tracking-wider">
                Tax
              </th>
              <th class="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-700 tracking-wider">
                Date
              </th>
              <th class="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-700 tracking-wider">
                Year
              </th>
              <th class="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-700 tracking-wider">
                Status
              </th>
              <th class="px-6 py-3 border-b-2 border-gray-300"></th>
            </tr>
          </thead>
          <tbody class="bg-white">
            <tr class="cursor-pointer hover:bg-gray-100 hover:font-semibold" v-for="assessment in assessments"
              :key="assessment.id">
              <td class="px-6  whitespace-no-wrap ">
                <div class="text-sm leading-5 text-gray-800 py-4">
                  {{ assessment.tin }}
                </div>
              </td>
              <td class="px-6  whitespace-no-wrap ">
                <div class="text-sm leading-5 text-gray-700 w-56 sm:w-32">
                  {{ assessment.name }}
                </div>
              </td>
              <td class="px-6  whitespace-no-wrap  text-gray-700  text-sm leading-5">
                {{ assessment.assessment_type }}
              </td>
              <td class="px-6  whitespace-no-wrap  text-gray-700  text-sm leading-5">
                {{ formatAmount(assessment.gross_income) }}
              </td>
              <td class="px-6  whitespace-no-wrap  text-gray-700  text-sm leading-5">
                {{ formatAmount(assessment.tax_payable) }}
              </td>
              <td class="px-6  w-36 whitespace-no-wrap  text-gray-700 text-sm leading-5">
                <div class="text-sm leading-5 text-gray-700 w-44 sm:w-28">
                  {{ formatDate(assessment.createdAt) }}
                </div>
              </td>
              <td class="px-6  whitespace-no-wrap  text-gray-700 text-sm leading-5">
                {{ assessment.assessment_year }}
              </td>
              <td class="px-6  whitespace-no-wrap  text-gray-700  text-sm leading-5">
                <span :class="
                  `relative inline-block px-3 py-1 pb-2 rounded-full font-semibold leading-tight ${assessment.status === 'approved'
                    ? ' bg-green-200 text-green-700 '
                    : ' bg-yellow-200 text-yellow-700 '
                  } `
                ">
                  <span aria-hidden class="absolute inset-0  opacity-50"></span>
                  <span class="relative text-xs">{{ assessment.status }}</span>
                </span>
              </td>

              <td class="px-6  whitespace-no-wrap text-right  text-sm leading-5">
                <button @click.prevent="printBill(assessment)" :disabled="
                  assessment.status === 'pending' || preparingNotice
                    ? true
                    : false
                " class="px-2
                   py-2  rounded transition duration-300  focus:outline-none border-2" :class="
                     `${assessment.status === 'pending'
                       ? 'border-gray-300 text-gray-300'
                       : 'border-blue-500  text-blue-500 hover:bg-blue-700 hover:text-white'
                     }`
                   ">
                  <svg v-if="assessment_id !== assessment.id" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5"
                    viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd"
                      d="M5 4v3H4a2 2 0 00-2 2v3a2 2 0 002 2h1v2a2 2 0 002 2h6a2 2 0 002-2v-2h1a2 2 0 002-2V9a2 2 0 00-2-2h-1V4a2 2 0 00-2-2H7a2 2 0 00-2 2zm8 0H7v3h6V4zm0 8H7v4h6v-4z"
                      clip-rule="evenodd" />
                  </svg>

                  <i v-else :class="
                    `lg:focus:shadow-sm w-5 text-center fas fa-circle-notch text-lg animate-spin`
                  "></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <!--        <div-->
        <!--          class="sm:flex-1 sm:flex sm:items-center sm:justify-between mt-4 work-sans"-->
        <!--        >-->
        <!--          <div>-->
        <!--            <p class="text-sm leading-5 text-blue-700">-->
        <!--              Showing-->
        <!--              <span class="font-medium">1</span>-->
        <!--              to-->
        <!--              <span class="font-medium">200</span>-->
        <!--              of-->
        <!--              <span class="font-medium">2000</span>-->
        <!--              results-->
        <!--            </p>-->
        <!--          </div>-->
        <!--          <div>-->
        <!--            <nav class="relative z-0 inline-flex shadow-sm">-->
        <!--              <div>-->
        <!--                <a-->
        <!--                  href="#"-->
        <!--                  class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"-->
        <!--                  aria-label="Previous"-->
        <!--                  v-on:click.prevent="changePage(pagination.current_page - 1)"-->
        <!--                >-->
        <!--                  <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">-->
        <!--                    <path-->
        <!--                      fill-rule="evenodd"-->
        <!--                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"-->
        <!--                      clip-rule="evenodd"-->
        <!--                    />-->
        <!--                  </svg>-->
        <!--                </a>-->
        <!--              </div>-->
        <!--              <div>-->
        <!--                <a-->
        <!--                  href="#"-->
        <!--                  class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-blue-700 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-tertiary active:text-gray-700 transition ease-in-out duration-150 hover:bg-tertiary"-->
        <!--                >-->
        <!--                  1-->
        <!--                </a>-->
        <!--                <a-->
        <!--                  href="#"-->
        <!--                  class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-blue-600 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-tertiary active:text-gray-700 transition ease-in-out duration-150 hover:bg-tertiary"-->
        <!--                >-->
        <!--                  2-->
        <!--                </a>-->
        <!--                <a-->
        <!--                  href="#"-->
        <!--                  class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-blue-600 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-tertiary active:text-gray-700 transition ease-in-out duration-150 hover:bg-tertiary"-->
        <!--                >-->
        <!--                  3-->
        <!--                </a>-->
        <!--              </div>-->
        <!--              <div v-if="pagination.current_page < pagination.last_page">-->
        <!--                <a-->
        <!--                  href="#"-->
        <!--                  class="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"-->
        <!--                  aria-label="Next"-->
        <!--                >-->
        <!--                  <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">-->
        <!--                    <path-->
        <!--                      fill-rule="evenodd"-->
        <!--                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"-->
        <!--                      clip-rule="evenodd"-->
        <!--                    />-->
        <!--                  </svg>-->
        <!--                </a>-->
        <!--              </div>-->
        <!--            </nav>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, computed } from "vue";
import DatePicker from "@/components/DatePicker2.vue";
import DropDown from "../shared/DropDown";
import PropertyTaxDemandNoticePreview from "./PropertyTaxDemandNoticePreview.vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import moment from "moment";
import numeral from "numeral";
export default {
  name: "SearchAssessment",
  components: {
    DatePicker,
    DropDown,
    PropertyTaxDemandNoticePreview
  },
  setup() {
    const selectedDates = ref({
      startDate: new Date(),
      endDate: new Date(),
    });
    const dateRangeFilter = ref(false);
    const store = useStore();
    const assessmentYearFilter = ref(false);
    const propertyToPreview = ref({});
    const openPropertyTaxDemandNoticePreviewModal = ref(false);
    const assessment_year = ref(new Date().getFullYear());
    const assessmentTypes = ref(["all", "PIT", "BIM", "PAYE", "PT"]);
    const fetchingAssessments = ref(false);
    const filter = reactive({
      startDate: selectedDates.value.startDate,
      endDate: selectedDates.value.endDate,
      assessment_year: moment().year(),
      assessment_type: assessmentTypes.value[0],
      tin: "",
    });
    const loadingData = ref(false);
    const preparingNotice = ref(false);
    const assessment_id = ref(0);
    // const pdfData = ref(null);

    //computed properties
    const assessments = computed(
      () => store.getters["assessment/getAssessments"]
    );

    function formatAmount(amt) {
      return numeral(amt).format("0,0.00");
    }
    function formatDate(dt) {
      return moment(dt).format("DD-MMM-YYYY h:mm:ss a");
    }
    function updateStartDate(newDate) {
      selectedDates.value.startDate = newDate;
      filter.startDate = newDate;
    }
    function updateEndDate(newDate) {
      selectedDates.value.endDate = newDate;
      filter.endDate = newDate;
    }
    function selectType(type) {
      filter.assessment_type = type;
    }
    async function printBill(assessment) {
      try {

        console.log(assessment)
        if (assessment.status === "approved") {

          let result;
          assessment_id.value = assessment.id;
          preparingNotice.value = true;
          if (assessment.assessment_type === "PT") {
            result = await store.dispatch(
              "assessment/getPropertyTaxResult",
              { url: "taxpayer/generateDemandNotice", data: { assessment_ref: assessment.assessment_ref } }
            );


            propertyToPreview.value = result;
            openPropertyTaxDemandNoticePreviewModal.value = true;

          } else {

            result = await store.dispatch(
              "assessment/getAssessmentBill",
              assessment.assessment_ref
            );

            await window.open(
              URL.createObjectURL(
                new Blob([result.data], { type: "application/pdf" })
              ),
              "_blank"
            );
          }


        } else {
          new Swal.fire({
            title: "Revenue House",
            text: "Assessment pending approval, please try again later...",
            icon: "warning",
          });
        }
      } catch (e) {
        console.log(e);
        new Swal.fire({
          title: "Revenue House",
          text: e,
          icon: "error",
        });
      } finally {
        preparingNotice.value = false;
        assessment_id.value = 0;
      }
    }

    async function search() {
      try {
        fetchingAssessments.value = true;
        filter.startDate = moment(filter.startDate).format("YYYY-MM-DD");
        filter.endDate = moment(filter.endDate).format("YYYY-MM-DD");

        if (!dateRangeFilter.value) {
          delete filter.startDate;
          delete filter.endDate;
        }

        if (!assessmentYearFilter.value) delete filter.assessment_year;

        await store.dispatch("assessment/fetchAssessmentsFromDB", filter);
      } catch (e) {
        console.log(e);
        new Swal.fire({
          title: "Revenue House",
          text: e,
          icon: "error",
        });
      } finally {
        fetchingAssessments.value = false;
      }
    }

    return {
      loadingData,
      assessments,
      formatAmount,
      formatDate,
      search,
      selectedDates,
      fetchingAssessments,
      assessmentTypes,
      selectType,
      dateRangeFilter,
      assessment_year,
      assessmentYearFilter,
      updateStartDate,
      updateEndDate,
      filter,
      printBill,
      preparingNotice,
      assessment_id,
      openPropertyTaxDemandNoticePreviewModal,
      propertyToPreview
    };
  },
};
</script>

<style scoped>
</style>
