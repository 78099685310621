<template>
  <content-holder :title="''" class="main-h overflow-y-scroll p-4">
    <alert-banner v-if="alert.state" :alert="alert" @close="alert.state = false"></alert-banner>

    <div v-if="loadingData" class="w-full mt-32 flex items-center text-center">
      <i :class="
        `lg:focus:shadow-sm w-12 mx-auto text-center fas fa-circle-notch mt-4 text-5xl text-blue-primary animate-spin`
      "></i>
    </div>

    <div v-else-if="!loadingData && assessmentRequests.length < 1">
      <p
        class="flex items-center transition text-center text-white bg-blue-primary font-medium text-md md:text-lg w-full mb-2 px-4 py-4 rounded-md">
        <span class="w-full">No assessment requests at this moment...</span>
      </p>
    </div>
    <div v-else>
      <div class="w-full sm:flex sm:items-center sm:justify-between">
        <button v-show="unit === 'assessment' && role === 'director'" type="button" @click="authorizeAll"
          class="w-full sm:w-auto shadow-sm bg-green-500 sm:rounded-md rounded-md mb-4 mt-6 px-3 py-1 text-white text-center font-medium focus:outline-none">
          <div v-if="!authorizingAll">Authorize all</div>
          <div v-else class="flex items-center space-x-4 justify-center">
            <i class="fas fa-circle-notch animate-spin"></i>
            <span>Please wait...</span>
          </div>
        </button>
        <div class="flex items-center bg-gray-50 px-2 py-2 shadow-sm font-bold">
          <p>Tax due : ₦{{ totalAssessmentTaxDue }}</p>
        </div>
      </div>
      <div class="mx-0 sm:mx-0 overflow-auto hide-scrollbar mt-6 sm:mt-0">
        <div class="py-2 align-middle inline-block min-w-full">
          <div class="shadow overflow-auto hide-scrollbar border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th v-show="unit === 'assessment' && role === 'director'" scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                    <span class="sr-only">Edit</span>
                    Actions
                  </th>
                  <th scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    TIN
                  </th>
                  <th scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Tax payer
                  </th>
                  <th scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Type
                  </th>
                  <th scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Gross
                  </th>
                  <th scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Tax
                  </th>
                  <th scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Date
                  </th>
                  <th scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Year
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="req in assessmentRequests" :key="req.audit_ref">
                  <td v-show="unit === 'assessment' && role === 'director'"
                    class="pr-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <div class="flex items-center space-x-4">
                      <button type="button" @click="authorize(req.id)"
                        class="bg-green-500 rounded-md ml-2 px-3 py-1 text-white text-center font-medium focus:outline-none">
                        <div v-if="reqId != req.id">Authorize</div>
                        <div v-else class="flex items-center space-x-4 justify-center">
                          <i class="fas fa-circle-notch animate-spin"></i>
                          <span>Please wait...</span>
                        </div>
                      </button>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm font-medium text-gray-900">
                      {{ req.tin }}
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm font-medium text-gray-900">
                      {{ req.name }}
                    </div>
                  </td>
                  <td class="px-6 py-4 break-normal">
                    <div class="text-sm text-gray-900">
                      {{ req.assessment_type }}
                    </div>
                  </td>
                  <td class="px-6 py-4 break-normal">
                    <div class="text-sm text-gray-900">
                      {{ formatAmount(req.gross_income) }}
                    </div>
                  </td>
                  <td class="px-6 py-4 break-normal">
                    <div class="text-sm text-gray-900">
                      {{ formatAmount(req.tax_payable) }}
                    </div>
                  </td>
                  <td class="px-6 py-4 break-normal">
                    <div class="text-sm text-gray-900 w-32">
                      {{ formatDate(req.createdAt) }}
                    </div>
                  </td>
                  <td class="px-6 py-4 break-normal">
                    <div class="text-sm text-gray-900">
                      {{ req.assessment_year }}
                    </div>
                  </td>
                </tr>

                <!-- More items... -->
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </content-holder>
</template>

<script>
import ContentHolder from "@/components/ContentHolder.vue";

import { role, unit } from "@/helpers/login_details.js";
import { mapGetters } from "vuex";
import { globalMixin } from "@/mixins/global.js";
import AlertBanner from "@/components/AlertBanner.vue";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import numeral from "numeral";
export default {
  components: { ContentHolder, AlertBanner },
  mixins: [globalMixin],
  data: () => ({
    unit: unit,
    role: role,
    authorizingSingle: false,
    reqId: 0,
    authorizingAll: false,
    loadingData: false,
    print: false,
    assessmentDetails: "",
    companyName: "",
    assessmentInterval: null,
  }),
  computed: {
    ...mapGetters({ assessmentRequests: ["assessment/getAssessmentRequests"] }),
    totalAssessmentTaxDue() {
      let total = 0;
      this.assessmentRequests.forEach((req) => {
        total += parseInt(req.tax_payable);
      });
      return numeral(total).format("0,0.00");
    },
    // assessmentRequests() {
    //   console.log(this.getAssessmentRequests);
    //   return this.getAssessmentRequests;
    // },
  },
  methods: {
    formatAmount(amt) {
      return numeral(amt).format("0,0.00");
    },
    formatDate(dt) {
      return dayjs(dt).format("DD-MMM-YYYY h:mm:ss a");
    },
    getStatus(req) {
      return req.status === "pending" ? true : false;
    },
    printAudit(details) {
      //    console.log(details);
      this.assessmentDetails = details;
      this.print = true;
    },
    authorize(id) {
      this.stopFetchingAssessments();
      this.authorizingSingle = true;
      this.reqId = id;
      this.$store
        .dispatch("assessment/authorizeSingleAssessment", { ids: [id] })
        .then(() => {
          this.authorizingSingle = false;
          this.reqId = 0;
          setTimeout(() => {
            this.startFetchingAssessments();
          }, 3000);
        })
        .catch((err) => {
          if (
            !err.includes("Network Error") &&
            !err.toLowerCase().includes("timeout")
          ) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: err,
            });
          }
          this.reqId = 0;
          this.authorizingSingle = false;
        });
    },
    authorizeAll() {
      this.authorizingAll = true;
      this.stopFetchingAssessments();
      let ids = [];
      this.assessmentRequests.forEach((r) => {
        ids.push(r.id);
      });

      this.$store
        .dispatch("assessment/authorizeAllAssessments", { ids: [...ids] })
        .then(() => {
          this.authorizingAll = false;
          this.showSuccess("All assessment requests approved!!!");
          setTimeout(() => {
            this.startFetchingAssessments();
          }, 3000);
        })
        .catch((err) => {
          if (
            !err.includes("Network Error") &&
            !err.toLowerCase().includes("timeout")
          ) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: err,
            });
          }
          this.authorizingAll = false;
        });
    },
    async stopFetchingAssessments() {
      await clearInterval(this.assessmentInterval);
      //  console.log("unmounted");
    },
    fetchAssessments() {
      this.$store
        .dispatch("assessment/fetchAssessmentRequestsFromDB", {
          status: "pending",
          assessment_type: "all",
        })
        .then(() => {
          this.loadingData = false;
        })
        .catch((err) => {
          if (
            !err.includes("Network Error") &&
            !err.toLowerCase().includes("timeout") &&
            !err.toLowerCase().includes("no record found")
          ) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: err,
            });
          }
          this.loadingData = false;
        });
    },
    startFetchingAssessments() {
      this.assessmentInterval = setInterval(() => {
        this.fetchAssessments();
      }, 20000);
    },
  },
  created() {
    this.loadingData = true;
    this.unit = unit;
    this.fetchAssessments();
  },
  mounted() {
    this.startFetchingAssessments();
  },

  beforeUnmount() {
    this.stopFetchingAssessments();
  },
};
</script>

<style>
</style>
