import { ref } from "vue";
export default function() {
  const alert = ref({
    state: false,
    mode: "error",
    msg: "It seems an error occurred, please try again...",
  });
  const timeout = ref(null);

  function showError(msg) {
    clearTimeout(timeout.value);
    alert.value.state = true;

    if (msg.toString().includes("timeout"))
      this.value.msg =
        "Sorry, you seem to be on a slow network. Kindly check your network and try again.";
    else this.value.msg = msg;

    alert.value.mode = "error";
    timeout.value = setTimeout(() => {
      alert.value.state = false;
    }, 6000);
  }

  function showAlert(msg) {
    clearTimeout(timeout.value);
    alert.value.state = true;
    alert.value.msg = msg;
    alert.value.mode = "success";
    timeout.value = setTimeout(() => {
      alert.value.state = false;
    }, 6000);
  }

  return {
    alert,
    showError,
    showAlert,
  };
}
