<template>
  <div class="pt-8  text-gray-dark sec-main-h overflow-y-auto px-6 sm:px-8 hide-scrollbar md:show-scrollbar ">
    <alert-banner class="w-full" v-if="alert.state" :alert="alert" @close="alert.state = false"></alert-banner>

    <div class="sm:flex sm:items-center sm:space-x-10">
      <div>
        <div class="w-full sm:w-80 flex items-center justify-between">
          <h3 class="text-gray-dark font-medium">Assessment type</h3>
          <button @click="reset" class="focus:outline-none text-red-500 font-medium hover:font-bold">
            reset
          </button>
        </div>

        <drop-down :items="assessmentTypes" :title="''"
          :classList="[' w-full sm:w-80 rounded border-2 border-blue-primary ']" @item-selected="selectItem" />
      </div>

      <div v-if="assessmentType !== 'PAYE'" class="w-full mt-6 sm:w-80 flex items-center justify-between">
        <div class="flex items-center space-x-2">
          <input type="checkbox" name="bus_prem" :checked="pitDetails.bus_prem"
            @change="pitDetails.bus_prem = !pitDetail.bus_prem" v-model="pitDetails.bus_prem"
            class="focus:ring cursor-pointer rounded-lg" />
          <span class="text-gray-600 font-medium rounded">Business Premises</span>
        </div>
      </div>
    </div>

    <paye-assessment v-if="assessmentType === 'PAYE'" :validation-scheme="validationScheme"
      @payeAssessment="payeAssessmentSubmitted" @result="handlePayeSaveResult" />
    <property-tax :enablePrint="true" :enableAssessmentYear="true" v-else-if="assessmentType === 'Property Tax'">
    </property-tax>
    <div v-else class="presumptive-tax relative">
      <vee-form @submit="assessTaxPayer" class="w-full mt-6 " :validation-scheme="validationScheme" v-slot="{ errors }">
        <div class="sm:flex sm:space-x-10">
          <div class="relative sm:w-80 mb-2 sm:mb-0">
            <div class="flex items-center justify-between">
              <label for="tin" class="leading-7 text-sm text-gray-600">TIN (JTB if available)</label>
            </div>
            <vee-field name="tin" :rules="validationScheme.tin" type="number" id="tin" autocomplete="on"
              v-model="pitDetails.tin" @input="assessed = false"
              class="w-full bg-white rounded border border-gray-300 focus:border-blue-primary focus:ring-2 focus:ring-blue-primary text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
            <span class="text-error">{{ errors.tin }}</span>
          </div>
          <div v-if="assessmentType === 'Presumptive'" class="relative sm:w-80 mb-2 sm:mb-0">
            <div class="flex items-center justify-between">
              <label for="income" class="leading-7 text-sm text-gray-dark">Gross Income</label>
            </div>
            <vee-field type="text" id="income" name="income" :rules="validationScheme.required"
              v-model="pitDetails.income" autocomplete="off" @input="assessed = false"
              class="w-full bg-white rounded border border-gray-300 focus:border-blue-primary focus:ring-2 focus:ring-blue-primary text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
          </div>
          <div v-if="assessmentType === 'BIM'" class="relative sm:w-80 mb-2 sm:mb-0">
            <p class="text-gray-dark mb-1 ">BIM Levy type</p>

            <b-i-m-drop-down :items="bimLevies" :title="''" :classList="[' w-full sm:w-80 ']"
              @bim-selected="selectBimLevy" />
          </div>
          <div class="relative sm:w-80 mb-2 sm:mb-0">
            <div class="flex items-center justify-between">
              <label for="assessment_year" class="leading-7 text-sm text-gray-dark">Assessment Year</label>
            </div>
            <vee-field type="number" id="assessment_year" name="assessment_year" :rules="validationScheme.required"
              v-model="pitDetails.assessment_year" autocomplete="off" @input="assessed = false"
              class="w-full bg-white rounded border border-gray-300 focus:border-blue-primary focus:ring-2 focus:ring-blue-primary text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
          </div>
        </div>

        <div class="w-full sm:flex sm:items-center sm:space-x-9">
          <button id="btnAssess" type="submit" :disabled="assessing"
            class="w-full lg:w-80 mt-8 px-8 py-2 font-semibold text-white text-lg transition duration-500 ease-in-out transform rounded-lg shadow-xl bg-gradient-to-r from-green-700 hover:from-green-600 to-green-600 hover:to-green-700 focus:ring focus:ring-green-700 focus:outline-none">
            <div v-if="!assessing">Assess taxpayer</div>
            <div v-else class="flex items-center space-x-4 justify-center">
              <i class="fas fa-circle-notch animate-spin"></i>
              <span>Please wait...</span>
            </div>
          </button>
          <button id="btnSaveAssess" @click.prevent="saveAssessment" type="button" :disabled="savingAssessment"
            class="w-full lg:w-80  mt-6 sm:mt-8 px-8 py-2 font-semibold text-white text-lg transition duration-500 ease-in-out transform rounded-lg shadow-xl bg-gradient-to-r from-blue-700 hover:from-blue-600 to-blue-600 hover:to-blue-700 focus:ring focus:ring-blue-700 focus:outline-none">
            <div v-if="!savingAssessment">Save assessment</div>
            <div v-else class="flex items-center space-x-4 justify-center">
              <i class="fas fa-circle-notch animate-spin"></i>
              <span>Please wait...</span>
            </div>
          </button>
          <!--  <button
            v-show="assessmentSaved"
            @click.prevent="printDemandNotice"
            class="w-full lg:w-80  mt-6 sm:mt-8 px-8 py-2 font-semibold text-white text-lg transition duration-500 ease-in-out transform rounded-lg shadow-xl bg-gradient-to-r from-pink-700 hover:from-pink-600 to-pink-600 hover:to-pink-700 focus:ring focus:ring-pink-700 focus:outline-none"
          >
            Print bill
          </button> -->
        </div>
      </vee-form>
      <div v-if="assessed" class="shadow-md rounded-2xl w-full mt-8 sm:w-12/12  p-4 py-6 mb-10 bg-white">
        <div class="flex flex-col items-center justify-center">
          <p class="text-2xl sm:text-4xl font-semibold">
            Assessment Information
          </p>
          <p class="text-gray-800 text-xl sm:text-5xl text-center font-medium mb-2 mt-4">
            {{ taxpayer?.name }}
          </p>
          <template v-if="assessmentType === 'Presumptive'">
            <p class="flex flex-col items-center justify-center sm:text-lg text-gray-800  text-left   mb-4 mt-2">
              Tax Due :
              <span class="font-medium text-lg sm:text-3xl">₦{{ formatAmount(assessment_details.tax_payable) }}</span>
            </p>
            <p class="flex flex-col items-center justify-center sm:text-lg text-gray-800 text-left   mb-4 mt-2">
              Tax Relief :
              <span class="font-medium text-lg sm:text-3xl">₦{{ formatAmount(assessment_details.tax_relief) }}</span>
            </p>
            <p class="text-gray-400 text-center text-lg px-2 mt-4">
              Payment for presumptive tax
            </p>
          </template>
          <template v-if="assessmentType === 'BIM'">
            <p class="flex flex-col items-center justify-center sm:text-lg text-gray-800 text-left   mb-4 mt-2">
              Tax Due :
              <span class="font-medium text-lg sm:text-4xl">₦{{
                  formatAmount(
                    pitDetails.bim_levy ? pitDetails.bim_levy.amount : 0
                  )
              }}</span>
            </p>
            <p class="text-gray-400 text-center text-lg px-2 mt-4">
              Payment for Bayelsa Infrastructure Maintenance tax
            </p>
          </template>
        </div>
      </div>
    </div>



    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="relative w-full" v-show="showDemandNotice">
      <div class="demandNotice relative" v-html="preAssessDemandNotice"></div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, computed, onBeforeMount, onMounted } from "vue";
// import { jsPDF } from "jspdf";
// import html2pdf from "html2pdf.js";
import numeral from "numeral";
import { useStore } from "vuex";
import AlertBanner from "@/components/AlertBanner.vue";
import useAlert from "@/helpers/alert.js";
import useAssessment from "@/mixins/assessment.js";
import DropDown from "@/components/shared/DropDown.vue";
import BIMDropDown from "@/components/shared/BIMDropDown.vue";
import PayeAssessment from "./PayeAssessment";
import { emitter } from "@/main.js";
import Swal from "sweetalert2";
import PropertyTax from "@/components/assessment/PropertyTax.vue";
export default {
  name: "CreateNew",
  components: { PayeAssessment, DropDown, BIMDropDown, AlertBanner, PropertyTax },
  setup() {
    const store = useStore();
    const showDemandNotice = ref(false);
    const { alert, showError, showAlert } = useAlert();
    const { pitDetails, resetPayeAssessment } = useAssessment();
    const validationScheme = reactive({
      required: "required",
      tin: "tin",
    });
    const assessmentTypes = ref(["Presumptive", "BIM", "PAYE", "Property Tax"]);
    // const bim_levy = ref({});
    const assessmentType = ref(assessmentTypes.value[0]);

    const assessing = ref(false);
    const savingAssessment = ref(false);
    const assessmentSaved = ref(false);
    const assessed = ref(false);
    const assessment_details = ref({});
    const pdfData = ref(null);

    //computed properties
    const taxpayer = computed(() => {
      return store.getters["assessment/getTaxpayer"];
    });
    const preAssessDemandNotice = computed(() => {
      return store.getters["assessment/getPresumptiveAssessmentDemandNotice"];
    });
    const bimLevies = computed(() => {
      return store.getters["assessment/getBimLevies"];
    });

    //methods
    const formatAmount = (amt) => {
      return numeral(amt).format("0,0.00");
    };
    const selectItem = (item) => {
      assessmentType.value = item;
      reset();
    };
    const selectBimLevy = (bim) => {
      pitDetails.value.bim_levy = bim;
    };
    const printDemandNotice = async () => {
      try {
        await window.open(
          URL.createObjectURL(
            new Blob([pdfData.value], { type: "application/pdf" })
          ),
          "_blank"
        );
        // reset();
      } catch (e) {
        console.log(e.message);
      }
    };
    const reset = () => {
      pitDetails.value.assessment_year = new Date().getFullYear();
      pitDetails.value.income = 0;
      pitDetails.value.saveRecord = false;

      assessmentSaved.value = false;
      assessed.value = false;
      pdfData.value = null;

      resetPayeAssessment();

      // alert.value.state = false;
    };
    const assessTaxPayer = async (values) => {
      try {
        assessing.value = true;
        let result;
        if (assessmentType.value !== "BIM") {
          result = await store.dispatch("assessment/assessTaxPayer", {
            mode: "get",
            type: assessmentType.value,
            data: values,
          });



          const { status, msg, response } = result.data;

          if (status === "error") {
            showError(msg);
          } else {
            assessment_details.value = response;
            showAlert(msg);
          }
        }
        assessing.value = false;
        assessed.value = true;
      } catch (error) {
        console.log(error);
        assessing.value = false;
        showError(error);
      }
    };
    const payeAssessmentSubmitted = async (values) => {
      try {
        let result;
        values.saveRecord = false;
        if (assessmentType.value !== "BIM") {
          result = await store.dispatch("assessment/assessTaxPayer", {
            mode: "get",
            type: assessmentType.value,
            data: values,
          });
          // console.log(result);
          const { status, msg, response } = result.data;

          if (status === "error") {
            showError(msg);
          } else {
            emitter.emit("paye_assessment", response);
            showAlert(msg);
          }
        }
      } catch (e) {
        console.log(e);
        showError(e);
      }
    };
    const saveAssessment = async () => {
      try {
        if (assessed.value) {
          savingAssessment.value = true;
          pitDetails.value.saveRecord = true;

          if (assessmentType.value !== "BIM") delete pitDetails.value.bim_levy;

          let result = await store.dispatch("assessment/assessTaxPayer", {
            mode: "save",
            type: assessmentType.value,
            data: pitDetails.value,
          });

          showAlert(result);
          reset();
        } else showError("Please assess taxpayer before saving record!!!");
      } catch (e) {
        // console.log(e);
        new Swal.fire({
          title: "Revenue House",
          text: e,
          icon: "error",
        });
      } finally {
        savingAssessment.value = false;
      }
    };

    const handlePayeSaveResult = (payload) => {
      if (payload.status === "error") {
        showError(payload.msg);
      } else {
        showAlert(payload.msg);
        reset();
      }
    };

    //lifecycle hooks
    onMounted(() => { });
    onBeforeMount(async () => {
      try {
        await store.dispatch("assessment/getBimLeviesFromDB");
        pitDetails.value.bim_levy = bimLevies.value[0];
      } catch (e) {
        console.log(e);
      }
    });

    return {
      handlePayeSaveResult,
      preAssessDemandNotice,
      payeAssessmentSubmitted,
      assessment_details,
      printDemandNotice,
      showDemandNotice,
      savingAssessment,
      assessmentSaved,
      selectBimLevy,
      saveAssessment,
      validationScheme,
      formatAmount,
      assessing,
      selectItem,
      assessmentType,
      assessed,
      bimLevies,
      taxpayer,
      showError,
      showAlert,
      alert,
      reset,
      assessTaxPayer,
      pitDetails,
      assessmentTypes,
    };
  },
};
</script>

<style scoped>
</style>
