<template>
  <div>
    <div
      v-if="canViewAssessment"
      :title="''"
      style="padding-top: 0px !important;"
    >
      <div class="w-full" style="border-bottom: 0px solid #eaeaea">
        <ul class="flex cursor-pointer">
          <li
            v-for="tab in tabs"
            :key="tab"
            @click="selectedTab = tab"
            class="transition btnTab duration-300 ease-in-out py-2 px-6 text-blue-primary text-center"
            :class="
              `${
                selectedTab !== tab
                  ? 'border-gray-300'
                  : 'border-blue-primary-dark '
              }`
            "
          >
            {{ tab }}
          </li>
          <!-- <li class='py-2 px-6 bg-white rounded-t-lg  '>Akun</li> -->
        </ul>
      </div>
      <transition name="fade">
        <create-new v-if="selectedTab === 'New'"></create-new>
        <search-assessment
          v-else-if="selectedTab === 'Search'"
        ></search-assessment>
        <authorize v-else></authorize>
      </transition>
    </div>
    <content-holder v-else :title="'Assessments'" class="p-6">
      <unauthorized-access></unauthorized-access>
    </content-holder>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import ContentHolder from "../components/ContentHolder.vue";
import CreateNew from "../components/assessment/CreateNew.vue";
import SearchAssessment from "../components/assessment/SearchAssessment.vue";
import Authorize from "../components/assessment/Authorize.vue";
import UnauthorizedAccess from "@/components/shared/UnauthorizedAccess.vue";
import { views, role } from "@/helpers/login_details.js";
export default {
  components: {
    ContentHolder,
    SearchAssessment,
    Authorize,
    CreateNew,
    UnauthorizedAccess,
  },
  data: () => ({
    canViewAssessment: false,
  }),
  setup() {
    const tabs = ref(["New", "Search", "Authorize"]);

    const selectedTab = ref(tabs.value[0]);

    onMounted(() => {
      if (role === "basic") tabs.value = ["New", "Search"];
    });

    return {
      tabs,
      selectedTab,
    };
  },
  created() {
    if (views.includes("Assessment")) {
      this.canViewAssessment = true;
    } else {
      this.canViewAssessment = false;
    }
  },
};
</script>
